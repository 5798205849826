:root{
  --primary: #4B006E;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.OpenAppContainer{
  margin-right: 10px;
}

.OpenAppButton{
    color: white;
    font-family: Roboto;
    max-width:250px;
    font-size: bold;
    background: var(--primary);
    border-radius: 15px;
}

p{
  font-size: 100%;
  color: black;
}

.DataForm {
    display: flex;
    align-items: center;
    text-align: center;
    align-content: space-evenly;
    justify-content: center;
    flex-flow: column wrap;
    gap: 1rem;
    margin-bottom: 25px;
}

.FormField {
    display: block;
    position: relative;
    align-content: space-evenly;
    gap: 1rem;
    align-items: flex-start;
    width: 100%;
   
}

.Input {
    display: flex;
    position: relative;
    border: 1px solid grey;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding:8px;
    height: 40px;
}

.Input:focus {
    outline: none;
    box-sizing: border-box;
    border: 2px solid var(--primary);
    border-radius: 8px;
}

.FormFieldLabel {
    display: flex;
    gap: 3px;
    align-items: center;
    color: grey;
    font-size:10px;
    font-family: "Roboto", sans-serif;
    padding: 0px;
    position: relative;
    font-size: medium;
}

.SubmitButton {
    width: 80%;
    max-width:250px;
    height: 48px;
    color: white;
    font-family: Roboto;
    font-size: medium;
    background: var(--primary);
    border-radius: 15px;
    margin-top: 10px;
}

.SavetoDeviceButton {
    width: 216px;
    height: 48px;
    color: white;
    font-family: Roboto;
    font-size: smaller;
    background: var(--primary);
    border-radius: 15px;
}

.Error {
    color: #cc0033;
    font-size: 12px;
    line-height: 15px;
    text-align: left;
   
}

h4{
  width: auto;
    height: auto;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 26px;
    line-height: 18px;
    color: var(--primary);
}

.GridContainer {
    display: grid;
    grid-template-columns: auto 40%;
    text-align: left;
    padding-right: 3%;
    padding-left: 3%;
    margin-bottom: 16px;
    margin-top: 16px;
    color: dimgrey;
}

.InfoContainer{
  display: grid;
    grid-template-columns: auto;
    padding-right: 3%;
    padding-left: 3%;
    margin-top: 15px;
    margin-left: 10px;
    margin-bottom: 20px;
    color: black;
}

.DataFormImage{
  aspect-ratio: auto 200 / 100;
  max-width: 50px;
  width: 50px;
  margin-left: 10px;
  border-radius: 12px;
}

.StoreContainer{
  align-items: center;
  display: grid;
  grid-template-columns: auto auto;
  padding-right: 3%;
  padding-left: 3%;
  justify-items: center;
  justify-content: space-around;
}

.DownloadImage{
  aspect-ratio: auto 200 / 100;
  max-width: 25em;
  width: 70%;
  margin-left: 10px;
  border-radius: 12px;
}

.DownloadPromoInfo{
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.Divider {
    width: auto;
    margin-left: 3%;
    margin-right: 3%;
    height: 0px;
    opacity: 0.72;
    border: 1px solid rgba(111, 119, 137, 0.3);
}

.PromotionDetails{
  color: dimgrey;
  display: flex;
  align-items: center;
    font-family: Roboto;
    font-style: normal;
    font-size: 100%;
    line-height: 120%;
}

pre {
    font-family: Roboto;
    color: #3F414E;
    line-height: 21px;
}

h3 {
    width: auto;
    height: auto;
    margin-top: 5%;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 21px;
    color: var(--primary);
}

.card-title {
    padding-top: 20px;
    float: left;
    font-size: 36px;
    font-weight: bold;
    margin: 0;
}

.QRCodeTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: auto;
}

.QRImage{
  border-radius: 12px;
}

.pre2 {
    width: 185px;
    height: 3px;
    left: 43px;
    bottom: 4px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #3F414E;
}